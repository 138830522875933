const topEachCntntMain = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {


    const pinFunc = (elem) =>{
      gsap.to(elem, {
        scrollTrigger: {
          trigger: `${elem} .js-bg-wrap`,
          start: 'top top',
          endTrigger: elem,
          end: 'bottom top',
          // scrub: true,
          pin: true,
          // pinSpacing: false,
          // markers: true,
        },
      });
    }
    
    const openFunc = (elem) =>{
      ScrollTrigger.create({
        trigger: elem,
        start: 'top center',
        endTrigger: 'body',
        end: 'bottom top', 
        toggleClass: {targets: `${elem} .js-bg-wrap`, className: 'open'},
        // markers: true,
      });
    }

    const openFullFunc = (elem) =>{
      ScrollTrigger.create({
        trigger: elem,
        start: '200px top',
        endTrigger: 'body',
        end: 'bottom top', 
        toggleClass: {targets: `${elem} .js-bg-wrap`, className: 'open-full'},
        // markers: true,
      });
    }

    const coverOnFunc = (elem) =>{
      ScrollTrigger.create({
        trigger: `${elem} .js-concept-wrap`,
        start: 'top bottom',
        endTrigger: 'body',
        end: 'bottom top', 
        toggleClass: {targets: `${elem} .js-bg-wrap`, className: 'cover-on'},
        // markers: true,
      });
    }


    // sec-cntnt-1
    pinFunc('#jsEachCntnt1')
    openFunc('#jsEachCntnt1')
    openFullFunc('#jsEachCntnt1')
    coverOnFunc('#jsEachCntnt1')

    // sec-cntnt-2
    pinFunc('#jsEachCntnt2')
    openFunc('#jsEachCntnt2')
    openFullFunc('#jsEachCntnt2')

    // sec-cntnt-3
    pinFunc('#jsEachCntnt3')
    openFunc('#jsEachCntnt3')
    openFullFunc('#jsEachCntnt3')

    // sec-cntnt-4
    pinFunc('#jsEachCntnt4')
    openFunc('#jsEachCntnt4')
    openFullFunc('#jsEachCntnt4')


    // sec-cntnt-1
    // gsap.to('#jsEachCntnt1', {
    //   scrollTrigger: {
    //     trigger: '#jsEachCntnt1 .js-bg-wrap',
    //     start: 'top top',
    //     endTrigger: '#jsEachCntnt1',
    //     end: 'bottom top',
    //     // scrub: true, //スクロール量に応じて動かす
    //     pin: true, //ピン留め
    //     // pinSpacing: false,
    //     // markers: true,
    //   },
    // });
    // ScrollTrigger.create({
    //   trigger: '#jsEachCntnt1',
    //   start: 'top center',
    //   endTrigger: 'body',
    //   end: 'bottom top', 
    //   toggleClass: {targets: "#jsEachCntnt1 .js-bg-wrap", className: "open"},
    //   // markers: true,
    // });
    // ScrollTrigger.create({
    //   trigger: '#jsEachCntnt1',
    //   start: 'top top',
    //   endTrigger: 'body',
    //   end: 'bottom top', 
    //   toggleClass: {targets: "#jsEachCntnt1 .js-bg-wrap", className: "open-full"},
    //   // markers: true,
    // });
    // ScrollTrigger.create({
    //   trigger: '#jsEachCntnt1 .js-concept-wrap',
    //   start: 'top center',
    //   endTrigger: 'body',
    //   end: 'bottom top', 
    //   toggleClass: {targets: "#jsEachCntnt1 .js-bg-wrap", className: "cover-on"},
    //   // markers: true,
    // });





  }
})();
export default topEachCntntMain;