const comCoverFade = (() => {

  const fadeElem = document.querySelectorAll('.js-com-cover-fade');
  if (fadeElem.length > 0) {

    for (let i = 0; i < fadeElem.length; i++) {
      const coverElem = fadeElem[i].querySelector('.js-com-cover');

      gsap.to(coverElem, {
        width: 0,
        scrollTrigger: {
          trigger: fadeElem[i],
          start: 'top 80%'
        }
      });
    }

  }

})();
export default comCoverFade;