const planView = (() => {
  const pagePlan = (document.getElementById("pagePlan") != null);
  if (pagePlan) {

    let sW = window.innerWidth;

    let elem = document.getElementById("jsViewBox")
    let elemBtnL = document.getElementById("jsViewBtnL")
    let elemBtnR = document.getElementById("jsViewBtnR")

    let speed = 14; // スクロールのスピード（1に近いほど速く）
    let move = 1; // スクロールのなめらかさ（1に近いほどなめらかに）

    let moveVol = 0
    let clear

    let currX = 0;
    let maxX = 0;

    const scrollRight = () => {

      moveVol = moveVol + move
      elem.scrollLeft = moveVol;
      currX = elem.scrollLeft;
      console.log(currX);
      console.log(maxX);

      clear = setTimeout(scrollRight, speed);

      if (maxX == currX) {
        clearTimeout(clear)
      } else {
        maxX = currX
      }

    }


    const scrollLeft = () => {

      moveVol = moveVol - move
      elem.scrollLeft = moveVol;
      currX = elem.scrollLeft;
      console.log(currX);
      console.log(maxX);

      clear = setTimeout(scrollLeft, speed);

      if (maxX == currX) {
        clearTimeout(clear)
      } else {
        maxX = currX
      }
    }


    if(sW > 750){
      elemBtnL.addEventListener('mouseover', () => {
        speed = 4
        clearTimeout(clear)
        scrollLeft()
      });
      elemBtnL.addEventListener('mouseleave', () => {
        clearTimeout(clear)
      });
  
      elemBtnR.addEventListener('mouseover', () => {
        speed = 4
        clearTimeout(clear)
        scrollRight()
      });
      elemBtnR.addEventListener('mouseleave', () => {
        clearTimeout(clear)
      });
    }else{
      elem.addEventListener('touchstart', () => {
        clearTimeout(clear)
      });
    }




    gsap.to(elem, {
      scrollTrigger: {
        trigger: elem,
        start: 'top 80%',
        onEnter: () => {
          scrollRight()
        },
      }
    });


  }
})();
export default planView;