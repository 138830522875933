const comMain = () => {
  const jsComSecMain = (document.getElementById("jsComSecMain") != null);
  if (jsComSecMain) {


    let sW = window.innerWidth;


    gsap.to('#jsComSecMain', {
      scrollTrigger: {
        trigger: '#jsComSecMain .js-bg-wrap',
        start: 'top top',
        endTrigger: '#jsComSecMain',
        end: 'bottom top',
        // scrub: true, //スクロール量に応じて動かす
        pin: true, //ピン留め
        // pinSpacing: false,
        // markers: true,
      },
    });


    // ScrollTrigger.create({
    //   trigger: '#jsComSecMain',
    //   start: 'top center',
    //   endTrigger: 'body',
    //   end: 'bottom top', 
    //   toggleClass: {targets: "#jsComSecMain .js-bg-wrap", className: "open"},
    //   markers: true,
    // });
    setTimeout(()=>{
      document.querySelector('#jsComSecMain .js-bg-wrap').classList.add('open')
    }, 1000)


    let startPoint
    if(sW > 750){
      startPoint = '20px'
    }else{
      startPoint = '400px'
    }
    ScrollTrigger.create({
      trigger: '#jsComSecMain',
      start: `${startPoint} top`,
      endTrigger: 'body',
      end: 'bottom top', 
      toggleClass: {targets: "#jsComSecMain .js-bg-wrap", className: "open-full"},
      // markers: true,
    });


    // ScrollTrigger.create({
    //   trigger: '#jsEachCntnt1 .js-concept-wrap',
    //   start: 'top center',
    //   endTrigger: 'body',
    //   end: 'bottom top', 
    //   toggleClass: {targets: "#jsEachCntnt1 .js-bg-wrap", className: "cover-on"},
    //   // markers: true,
    // });


  }
};
export default comMain;