const qualityScroll = (() => {
  const pageQuality = (document.getElementById("pageQuality") != null);
  if (pageQuality) {

    ScrollTrigger.create({
      trigger: ".bg-wrap",
      pin: ".bg-box",
      // markers: true,
      start: "top top",
      endTrigger: ".bg-wrap",
      end: "bottom bottom",
      position: "fixed"
    });

    ScrollTrigger.create({
      trigger: ".sec-place",
      // markers: true,
      start: "top center",
      toggleClass: { targets: ".bg-wrap", className: "place" },
    })
    ScrollTrigger.create({
      trigger: ".sec-life",
      // markers: true,
      start: "top center",
      endTrigger: "body",
      end: "bottom top",
      toggleClass: { targets: ".bg-wrap", className: "life" },
    })

    ScrollTrigger.create({
      trigger: ".sec-design .flex-wrap",
      // markers: true,
      start: "top center",
      end: "bottom center",
      toggleClass: { targets: ".sec-design .bg-cover", className: "act" },
    })
    ScrollTrigger.create({
      trigger: ".sec-place .flex-wrap",
      // markers: true,
      start: "top center",
      end: "bottom center",
      toggleClass: { targets: ".sec-place .bg-cover", className: "act" },
    })
    ScrollTrigger.create({
      trigger: ".sec-life .flex-wrap",
      // markers: true,
      start: "top center",
      endTrigger: "body",
      end: "bottom top",
      toggleClass: { targets: ".sec-life .bg-cover", className: "act" },
    })


    const img2 = document.getElementsByClassName('js-flex-img-2');

    for (let i = 0; i < img2.length; i++) {
      gsap.to(img2[i], {
        scrollTrigger: {
          trigger: img2[i],
          start: 'center center',
          end: "bottom -1000px",
          toggleClass: { targets: img2[i], className: "act" },
          // markers: true,
        }
      });
    }

  }
})();
export default qualityScroll;