const topBgCover = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if(pageTop){

    let cover = document.getElementById('jsBgCover');
    let secMain = document.getElementById('jsSecMain');
  
    gsap.set(cover, { opacity: 0,});
    
    gsap.to(cover, { 
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: secMain,
        start: 'bottom 99%',
        toggleActions: 'play none none reverse',
        toggleClass: {targets: "#jsSecMain .js-scroll-box", className: "none"},
        // markers: true,
      }
    });
  
  }
})();
export default topBgCover;